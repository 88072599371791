import React, { useContext, useEffect } from "react";
import EssityContext from "../../context/EssityContext";
import Login from "../auth/Login";
import Navbar from "./Navbar";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const messages = {
  label: {
    clientIsInactive: "Client Is Inactive",
  },
};

const Landing = ({ match }) => {
  const history = useHistory();

  const {
    setCurrentClientUpdateData,
    checkUserAlreadyLogged,
    getClients,
    client,
  } = useContext(EssityContext);

  useEffect(async () => {
    await checkUserAlreadyLogged(history);
    setCurrentClientUpdateData(match.params.essity, history, true);
  }, []);

  // useEffect(() => {
  //   if (client?.clientSiteIs == 'webstore') {
  //     window.location.href = `https://dev.emerch.com.au/${match.params.essity}`;
  //   }
  // }, [client]);


  return (
    <>
      {client &&
        (client.status === "active" ? (
          <div>
            <Navbar
              login={true}
              logo={client.photo}
              name={match.params.essity}
              className="absoluteNavbarOnLanding"
            ></Navbar>
            <div className="landing">
              <img
                src={
                  client &&
                  (client?.landingPhoto !== "no-image.png" ?
                    `${process.env.REACT_APP_BACKEND_URL}/uploads/clients/${client?.landingPhoto}` :
                    "https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
                  )}
                alt="clientPhoto"
                className="landingImage"
              />
              <div className="loginPage">
                <Login match={match} history={history} clients={client}></Login>
              </div>
              <div className="landing-text container">
                {ReactHtmlParser(client.text)}
              </div>
            </div>
          </div>
        ) : (
          <div className="inactiveClient">
            <h1 className="display-4">{messages.label.clientIsInactive}</h1>
          </div>
        ))}
    </>
  );
};

export default Landing;
