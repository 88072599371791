import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isEmpty, isNaN } from "lodash";

import Navbar from "../layouts/Navbar";
import EssityContext from "../../context/EssityContext";
import ProductTable from "./ProductTable";
import PaginationServerSide from "../utils/PaginationServerSide";
import Spinner from "../utils/Spinner";
import placeOrderIcon from "../../logo/placeOrder.png";
import Search from '../utils/Search';


const messages = {
  label: {
    dashboard: "Home",
    cart: "Go to Cart",
    categories: "Back to Categories",
    theCategory: "The Category ",
    hasNoProducts: "Has No Products",
    show: "Show",
    entries: "entries",
  },
};

const Products = ({ match }) => {
  const history = useHistory();
  const { state } = useLocation();

  let {
    getAllProducts,
    products,
    cardItems,
    user,
    setCurrentClientUpdateData,
    loading,
    clearMsg,
    setMsg,
    msg,
    clearAllProducts,
    categorySkuPaginationAndSearchingState, categorySkuPagination
  } = useContext(EssityContext);

  let [productState, setProductState] = useState({
    quantity: 0,
    categoryName: "",
    categoryImage: "",
    page: categorySkuPagination.page ? categorySkuPagination.page : 1,
    limit: categorySkuPagination.limit ? (categorySkuPagination.limit) : 20,
    limitOption: [20, 50, "All"],
    searchOption: ["SKU", "Description"],
    searchField: categorySkuPagination.searchField ? categorySkuPagination.searchField : "",
    searchValue: categorySkuPagination.searchValue ? categorySkuPagination.searchValue : "",
    sortBy: "id",
    serachProductNotBySearchValue: true
  });
  const [orderBy, setOrderBy] = useState("asc");

  let currentProducts = [];
  currentProducts = products?.products?.skus || products?.skus;
  const client = JSON.parse(localStorage.getItem("client"));

  const setSearchingValues = (value) => {
    productState = { ...productState, searchField: value?.searchField, searchValue: value?.searchValue, page: 1, serachProductNotBySearchValue: false }
    setProductState({
      ...productState,
      searchField: value?.searchField,
      searchValue: value?.searchValue,
      page: 1,
      serachProductNotBySearchValue: false
    })
  }

  const changeProductState = (field, value) => {
    setProductState({
      ...productState,
      [field]: value,
    });
  };
  const onUnitChange = (id, newUnit, minimumOrder, roundingNumber) => {
    let findIndex = currentProducts.findIndex((pro) => pro.id === id);
    currentProducts[findIndex].currentUnit = newUnit;
  };
  const onAdd = (item) => {
    // console.log(item.currentUnit);
    item.minimumOrder = item.minimumOrder ? item.minimumOrder : 1;
    if (
      item.currentUnit === 0 ||
      item.minimumOrder === 0 ||
      isNaN(item.currentUnit)
    ) {
      setMsg("Please add some quantity");
    } else if (item.currentUnit < item.minimumOrder) {
      setMsg(`Please order minimum of ${item.minimumOrder}`);
    } else if (item.currentUnit) {
      if (item.roundingNumber !== 0) {
        let newUnit = item.currentUnit - item.minimumOrder;
        newUnit = newUnit % item.roundingNumber;

        if (newUnit === 0) {
          if (item.currentUnit <= 999999) {
            cardItems(item, user, item.currentUnit);
            setMsg(`${item.description} added to your cart`);
          }
        } else {
          setMsg(`Please order in multiples of ${item.roundingNumber}`);
          // setMsg(`Please order minimum of ${item.minimumOrder}`);

        }
      } else {
        if (item.currentUnit <= 999999) {
          cardItems(item, user, item.currentUnit);
          setMsg(`${item.description} added to your cart`);
        }
      }
    } else {
      cardItems(item, user, item.minimumOrder);
      setMsg(`${item.description} added to your cart`);
    }

    setTimeout(() => {
      clearMsg();
    }, 3000);
  };

  const onDetial = (item) => {
    history.push({
      pathname: `/${match.params.essity}/addtocart`,
      state: {
        item: item,
        id: match.params.categoryId,
        categorySkusAndDiscriptionFilter: state?.categorySkusAndDiscriptionFilter
      },
    });
  };

  const callbackFunctionFromPagination = (page) => {
    const pageNumber = parseInt(page);
    changeProductState("page", pageNumber);
  };
  const onChange = async (field, value) => {
    setProductState({
      ...productState,
      [field]: value,
    });
    if (field === "searchField" && value === "") {
      productState.searchField = "";
      productState.searchValue = "";
      await getSearchValue();
    }
  };
  const changeOrderBy = () => {
    if (orderBy === "asc") {
      setOrderBy("desc");
    } else {
      setOrderBy("asc");
    }
  };

  useEffect(async () => {
    const { page, limit, searchField, searchValue } = productState;
    await categorySkuPaginationAndSearchingState(page, limit, searchField, searchValue)
  }, [productState.page, productState.limit, productState.searchField, productState.searchValue])

  useEffect(() => {
    if (productState.searchField === "") {
      productState.searchValue = "";
    }
  }, [productState.searchField === ""]);

  useEffect(async () => {
    const localClient = localStorage.getItem("clientname");
    if (match.params.essity === localClient) {
      setCurrentClientUpdateData(match.params.essity, history);
      if (productState?.serachProductNotBySearchValue) {
        await getSearchValue();
      }
    } else {
      history.push({
        pathname: `/${match.params.essity}`,
      });
    }
    const localCurrentCategory = JSON.parse(
      localStorage.getItem("currentCategory")
    );

    if (localCurrentCategory?.name) {
      changeProductState(
        "categoryName",
        localCurrentCategory.name.toUpperCase()
      );
      changeProductState("categoryImage", localCurrentCategory.image);
    }
  }, [productState.page, productState.limit, productState.sortBy, orderBy]);

  const setChangeLimit = (newLimit) => {
    setProductState({
      ...productState,
      limit: newLimit,
      page: 1,
    });
  };

  const getSearchValue = async (setPageNumberOneIfWeSearchData) => {
    let { limit, page, searchValue, searchField, sortBy } = productState;
    page = setPageNumberOneIfWeSearchData ? 1 : page;
    setProductState({
      ...productState,
      page
    })
    await getAllProducts(
      match?.params?.categoryId.split("?")[0],
      client,
      limit,
      page,
      searchField,
      searchValue,
      sortBy,
      orderBy
    );

    productState = { ...productState, serachProductNotBySearchValue: true }
    setProductState({
      ...productState,
      serachProductNotBySearchValue: true
    })
  };

  const goToPlaceOrderCart = () => {
    const { page, limit, searchField, searchValue } = productState;
    clearAllProducts();
    localStorage.removeItem("currentCategory");
    history.push({
      pathname: `/${match.params.essity}/cart`,
    });
  }



  return (
    <div>
      <Navbar
        logout={true}
        cart={true}
        name={match.params.essity}
        logo={client?.photo || ""}
      ></Navbar>
      <div className="product">
        <div className="container-fluid px-5">
          <div className="productHeader">
            <h1 className="display-4">
              Place Order
              <img
                src={placeOrderIcon}
                alt="placeOrderIcon"
                className="iconOnCustomerSide"
              />
            </h1>
          </div>
        </div>

        <div className="container-fluid px-5 mb-4">
          {productState.categoryName && (
            <h1 className="text-center display-4 my-3">
              {productState.categoryName}
            </h1>
          )}


          <div className="table-show-limit">
            <div className="">
              {client.isCategoryEnabled && (
                <Link
                  className="btn button my-1 mr-3 btn-sm "
                  to={`/${match.params.essity}/categories`}
                  onClick={(e) => {
                    clearMsg();
                    clearAllProducts();
                    localStorage.removeItem("currentCategory");
                  }}
                >
                  {messages.label.categories}
                </Link>
              )}
              <Link
                className="btn button my-1 mr-3 btn-sm"
                to={`/${match.params.essity}/dashboard`}
                onClick={(e) => {
                  clearAllProducts();
                  localStorage.removeItem("currentCategory");
                }}
              >
                {messages.label.dashboard}
              </Link>
              {/* <Link
                className="btn button my-1 mr-3 btn-sm"
                to={`/${match.params.essity}/cart`}
                onClick={(e) => {
                  clearAllProducts();
                  localStorage.removeItem("currentCategory");
                }}
              >
                {messages.label.cart}
              </Link> */}
              <button className="btn button my-1 mr-3 btn-sm" onClick={goToPlaceOrderCart} >
                {messages.label.cart}
              </button>
            </div>

            {/* {products.length !== 0 && ( */}
            <div className="show-limit my-1 ">
              <div className="show-limit-inner">
                <p className="pt-3" style={{ fontWeight: "bold" }}>
                  {messages.label.show + "  "}
                </p>
                <select
                  name="range"
                  className="form-control mx-1"
                  style={{ width: "70px" }}
                  onChange={(e) => setChangeLimit(e.target.value)}
                  value={productState?.limit}
                >
                  {productState.limitOption.map((limitValue) => (
                    <option key={limitValue} value={limitValue}>
                      {limitValue}
                    </option>
                  ))}
                </select>
                <p className="pt-3" style={{ fontWeight: "bold" }}>
                  {messages.label.entries}
                </p>
              </div>
            </div>
            {/* )} */}
          </div>






          <div>
            <div className="mb-1">
              <Search
                getSearchValueFromTable={getSearchValue}
                setSearchingValues={setSearchingValues}
                serachOption={productState.searchOption}
                searchField={productState?.searchField}
                searchValue={productState?.searchValue}
                productSearching={true}
              />
            </div>
            {/* <div className="row mb-1">
              <div className="col-md-4">
                <select
                  className="form-control mb-1 selectList"
                  onChange={(e) => onChange("searchField", e.target.value)}
                  value={productState?.searchField}
                >
                  <option value="">Search All</option>
                  {productState.searchOption.map((options) => (
                    <option value={options} key={options}>
                      {options}
                    </option>
                  ))}
                </select>
              </div>
              {!isEmpty(productState.searchField) && (
                <div className="col-md-4">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control "
                      placeholder={`Search By ${productState.searchField}`}
                      aria-describedby="basic-addon2"
                      onChange={(e) => onChange("searchValue", e.target.value)}
                      value={productState?.searchValue}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text selectList"
                        id="basic-addon2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getSearchValue(true);
                        }}
                      >
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div> */}

            {loading && isEmpty(products) ? (
              <Spinner />
            ) : (
              <div>
                {loading && <Spinner />}

                {products?.length === 0 && !loading ? (
                  <h1 className="text-center">
                    {state?.categorySkusAndDiscriptionFilter
                      ? "No product available for this search"
                      : "No product available for this category"}
                  </h1>
                ) : (
                  <>
                    <div className="productTable">
                      <ProductTable
                        onDetial={onDetial}
                        onAdd={onAdd}
                        setQuantity={changeProductState}
                        client={client}
                        products={currentProducts}
                        onUnitChange={onUnitChange}
                        msg={msg}
                        onChangeContainerValue={onChange}
                        changeOrderBy={changeOrderBy}
                      />
                    </div>
                    <PaginationServerSide
                      callBack={callbackFunctionFromPagination}
                      total={products?.totalPages}
                      initialPage={productState.page - 1}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Products;
