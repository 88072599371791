import React from "react";
import footer from "../../assets/images/footerImage2.png";

const messages = {
  label: {
    Copyright: process.env.REACT_APP_FOOTER_TEXT,
  },
};
const Footer = () => {
  return (
    <footer className="clientFooter">
      <div>
        <img
          className="footerImage"
          src={footer}
          alt=""
        />{" "}
        {messages?.label?.Copyright?.split(" ")?.[0]} <b>{messages?.label?.Copyright?.split(" ")?.[1]}</b> | copyright {new Date().getFullYear()}
      </div>
    </footer>
  );
};

export default Footer;
