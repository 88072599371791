import React from "react";
import "./App.scss";
import { Route, Switch } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// componets list
import Landing from "./components/layouts/Landing";
import Dashboard from "./components/dashboard/Dashboard";
import Categories from "./components/categories/Categories";
import Products from "./components/product/Products";
import AddToCart from "./components/product/ProductDetial";
import Delivery from "./components/delivery/Delivery";
import MangeAccount from "./components/user/ManageAccount";
import Footer from "./components/layouts/Footer";
import OrderReview from "./components/order/OrderReview";
import Report from "./components/stockreport/StockReport";
import Cart from "./components/cart/Cart";
import StripePayment from "./components/payment/StripePayment";
// import Stripe from "./components/utils/Stripe";
import PageNotFound from "./components/utils/PageNotFound";
import HomePage from "./components/home";
import PrivateRouteClient from "./components/utils/PrivateRouteClient";
import PrivateRouteClientUser from "./components/utils/PrivateRouteClientUser";
import CategoryRouteProtect from "./components/utils/CategoryRouteProtect";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const App = () => {
  return (
    <div className="App">
      <main>
        <Switch>
        <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/notfound" component={PageNotFound}></Route>
          <Route
            exact
            path="/:essity"
            render={(history) => <Landing {...history}> </Landing>}
          ></Route>
          <PrivateRouteClient
            path="/:essity/dashboard"
            exact
            component={Dashboard}
          ></PrivateRouteClient>
          <CategoryRouteProtect
            exact
            path="/:essity/categories"
            component={Categories}
          ></CategoryRouteProtect>
          <PrivateRouteClient
            exact
            path="/:essity/categories/:categoryId"
            component={Products}
          ></PrivateRouteClient>
          
          <PrivateRouteClient
            exact
            path="/:essity/addtocart"
            component={AddToCart}
          ></PrivateRouteClient>

          <PrivateRouteClient
            exact
            path="/:essity/cart"
            component={Cart}
          ></PrivateRouteClient>

          <PrivateRouteClientUser
            exact
            path="/:essity/delivery"
            component={Delivery}
          ></PrivateRouteClientUser>
          <PrivateRouteClientUser
            exact
            path="/:essity/manageaccount"
            component={MangeAccount}
          ></PrivateRouteClientUser>
          <PrivateRouteClientUser
            exact
            path="/:essity/orderreview"
            component={OrderReview}
          ></PrivateRouteClientUser>
          <PrivateRouteClientUser
            exact
            path="/:essity/stockreport"
            component={Report}
          ></PrivateRouteClientUser>
          <Elements stripe={stripePromise}>
            <PrivateRouteClientUser
              exact
              path="/:essity/payment"
              component={StripePayment}
            ></PrivateRouteClientUser>
          </Elements>
        </Switch>
      </main>
      <Footer> </Footer>
    </div>
  );
};
export default App;
