import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";

import LogoutModel from "../utils/LogoutModel";
import EssityContext from "../../context/EssityContext";
const messages = {
  label: {
    login: "Login",
    logout: "Logout",
    delivery: "Delivery",
  },
};
const Navbar = ({ login, logout, cart, delivery, logo, name, className }) => {
  const {
    cardItem,
    setCardItem,
    grandTotal,
    logoutUser,
    removeError,
    clearAllProducts,
    user,
  } = useContext(EssityContext);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let temp = 0;
    if (cardItem.length !== 0) {
      localStorage.setItem("card", JSON.stringify(cardItem));
      cardItem.map((item) => (temp += parseFloat(item.totalPrice)));
      localStorage.setItem("total", temp);
      grandTotal();
    } else {
      setCardItem();
      grandTotal();
    }
    return;
  }, [cardItem]);

  const logoutClick = () => {
    history.push({
      pathname: `/${name}`,
    });
    logoutUser();
    removeError();
  };
  const confirmLogoutonClick = () => {
    setConfirmLogout(true);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg bg-light navbar-light px-5 ${className}`}
        style={{
          // marginBottom: "-10px",
          backgroundColor: "white",
        }}
      >
        {localStorage.getItem("isGuestMode") &&
          localStorage.getItem("isGuestMode") ? (
          <Link to={`/${localStorage.getItem("clientname")}`}>
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/uploads/clients/${logo}`}
              alt=""
              className="navbarImage"
            />
          </Link>
        ) : (
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/uploads/clients/${logo}`}
            alt=""
            className="navbarImage"
          />
        )}
        <button
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div id="navbar" className="collapse navbar-collapse clientNavbar">
          <ul className="navbar-nav ml-auto">
            {localStorage.token && logout && (
              <li className="nav-item text-center active"
                style={{ "zIndex": "2" }}
              >
                <button
                  type="button"
                  className="mt-2 logoutSureButton text-center"
                  data-toggle="modal"
                  data-target="#logoutModel"
                  onClick={confirmLogoutonClick}
                >
                  {messages.label.logout}
                </button>
              </li>
            )}
            {cart && (
              <li className="nav-item text-center" style={{ "zIndex": "2" }}>
                <Link
                  to={`/${name}/cart`}
                  className="nav-link active"
                  onClick={() => clearAllProducts()}
                >
                  {localStorage.token && <span className="mr-2">|</span>} Cart
                  {cardItem.length !== 0 && (
                    <small className="cart-quan ">{cardItem.length}</small>
                  )}
                </Link>
              </li>
            )}
            {delivery && (
              <li className="nav-item text-center" style={{ "zIndex": "2" }}>
                <Link to={`/${name}/delivery`} className="nav-link">
                  {messages.label.delivery}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
      {confirmLogout && <LogoutModel logoutClick={logoutClick} />}
    </>
  );
};

export default Navbar;
