import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import EssityContext from "../../context/EssityContext";
import Alerts from "../utils/Alerts";

const messages = {
  label: {
    like: "What Would You Like To Do ?",
    manageAccount: "Manage Account",
    placeAnOrder: "Place An Order",
    reviewAnOrder: "Review An Order",
    stockReport: "Stock Report",
  },
};

const Dashboard = ({ match }) => {
  let history = useHistory();
  let { setCurrentClientUpdateData, user, client, msg, clearMsg, clearAllPaginationState } = useContext(
    EssityContext
  );

  useEffect(async () => {
    setCurrentClientUpdateData(match.params.essity, history);
    await clearAllPaginationState();
    return;
  }, []);
  // console.log(client?.photo);
  return (
    <>
      {user && (
        <>
          <Navbar
            logout={true}
            logo={client !== undefined && client.photo ? client.photo : ""}
            cart={true}
            name={match.params.essity}
          ></Navbar>
          <div className="clientDashboard">
            <img
              src={
                client &&
                (client.landingPhoto !== "no-image.png" ?
                  `${process.env.REACT_APP_BACKEND_URL}/uploads/clients/${client?.landingPhoto}` :
                  "https://images.unsplash.com/photo-1538370965046-79c0d6907d47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
                )
              }

              alt="clientPhoto"
              className="dashboardImage"
            />
            <div className="dashboard-actions container">
              {/* {msg && <Alerts msg={msg}></Alerts>} */}
              {user.role === "approver" && (
                <div className="row">
                  <div className="col-md-3">
                    <Link
                      className="btn button btn-block mb-3 py-5 px-2"
                      type="button"
                      to={`/${match.params.essity}/manageaccount`}
                      onClick={(e) => clearMsg()}
                    >
                      <i className="fas fa-user fa-5x"></i>
                      <br />
                      {messages.label.manageAccount}
                    </Link>
                  </div>
                  <div className="col-md-3">
                    <Link
                      to={
                        client?.isCategoryEnabled
                          ? `/${match.params.essity}/categories`
                          : `/${match.params.essity}/categories/${client?._id}`
                      }
                      // to={`/${match.params.essity}/categories`}
                      className="btn button btn-block mb-3 py-5 px-2 "
                      onClick={(e) => clearMsg()}
                    >
                      <i
                        className="fa fa-shopping-cart fa-5x"
                        aria-hidden="true"
                      ></i>
                      <br />
                      {messages.label.placeAnOrder}
                    </Link>
                  </div>
                  <div className="col-md-3">
                    <Link
                      className="btn button btn-block mb-3 py-5 px-2"
                      type="button"
                      to={`/${match.params.essity}/orderreview`}
                      onClick={(e) => clearMsg()}
                    >
                      <i
                        className="fa fa-check-circle fa-5x"
                        aria-hidden="true"
                      ></i>
                      <br />
                      {messages.label.reviewAnOrder}
                    </Link>
                  </div>
                  <div className="col-md-3">
                    <Link
                      className="btn  btn-block mb-3 py-5 px-2 button"
                      type="button"
                      to={`/${match.params.essity}/stockreport`}
                      onClick={(e) => clearMsg()}
                    >
                      <i
                        className="fa fa-sticky-note fa-5x "
                        aria-hidden="true"
                      ></i>
                      <br />
                      {messages.label.stockReport}
                    </Link>
                  </div>
                </div>
              )}
              {user.role === "buyer" && (
                <div className="row">
                  <div className="col-md-5 offset-md-1">
                    <Link
                      className="btn btn-info btn-block mb-3 py-5 px-2 "
                      type="button"
                      to={`/${match.params.essity}/manageaccount`}
                      onClick={(e) => clearMsg()}
                    >
                      <i className="fas fa-user fa-5x"></i>
                      <br />
                      {messages.label.manageAccount}
                    </Link>
                  </div>
                  <div className="col-md-5 offset-md-1">
                    <Link
                      to={
                        client?.isCategoryEnabled
                          ? `/${match.params.essity}/categories`
                          : `/${match.params.essity}/categories/${client?._id}`
                      }
                      className="btn btn-info btn-block mb-3 py-5 px-2 "
                      onClick={(e) => clearMsg()}
                    >
                      <i
                        className="fa fa-shopping-cart fa-5x"
                        aria-hidden="true"
                      ></i>
                      <br />
                      {messages.label.placeAnOrder}
                    </Link>
                  </div>
                </div>
              )}
              {JSON.stringify(user) === JSON.stringify({}) && (
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <Link
                      to={
                        client?.isCategoryEnabled
                          ? `/${match.params.essity}/categories`
                          : `/${match.params.essity}/categories/${client?._id}`
                      }
                      className="btn btn-info btn-block mb-3 py-5 px-2"
                      onClick={(e) => clearMsg()}
                    >
                      <i
                        className="fa fa-shopping-cart fa-5x"
                        aria-hidden="true"
                      ></i>
                      <br />
                      {messages.label.placeAnOrder}
                    </Link>
                  </div>
                </div>
              )}
            </div>
            {msg && <Alerts msg={msg}></Alerts>}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{
                  // width: "22vw",
                  width: "50%",
                  marginBottom: "2rem",
                  marginTop: "10vh",
                  height: "28vh",
                  objectFit: "contain",
                }}
                src={
                  client &&
                  client?.photo &&
                  `${process.env.REACT_APP_BACKEND_URL}/uploads/clients/${client.photo}`
                }
                alt=""
              />
            </div> */}
            {/* <div
              className="dashboard-actions"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="container">
                <h1 className="dispay-4 text-center mb-5">
                  {messages.label.like}
                </h1>
                {msg && <Alerts msg={msg}></Alerts>}
                {user.role === "approver" && (
                  <div className="row">
                    <div className="col-md-3">
                      <Link
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        type="button"
                        to={`/${match.params.essity}/manageaccount`}
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.manageAccount}
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        to={
                          client?.isCategoryEnabled
                            ? `/${match.params.essity}/categories`
                            : `/${match.params.essity}/categories/${client?._id}`
                        }
                        // to={`/${match.params.essity}/categories`}
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.placeAnOrder}
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        type="button"
                        to={`/${match.params.essity}/orderreview`}
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.reviewAnOrder}
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        type="button"
                        to={`/${match.params.essity}/stockreport`}
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.stockReport}
                      </Link>
                    </div>
                  </div>
                )}
                {user.role === "buyer" && (
                  <div className="row">
                    <div className="col-md-5 offset-md-1">
                      <Link
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        type="button"
                        to={`/${match.params.essity}/manageaccount`}
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.manageAccount}
                      </Link>
                    </div>
                    <div className="col-md-5 offset-md-1">
                      <Link
                        to={
                          client?.isCategoryEnabled
                            ? `/${match.params.essity}/categories`
                            : `/${match.params.essity}/categories/${client?._id}`
                        }
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.placeAnOrder}
                      </Link>
                    </div>
                  </div>
                )}
                {JSON.stringify(user) === JSON.stringify({}) && (
                  <div className="row">
                    <div className="col-md-6 offset-md-3">
                      <Link
                        to={
                          client?.isCategoryEnabled
                            ? `/${match.params.essity}/categories`
                            : `/${match.params.essity}/categories/${client?._id}`
                        }
                        className="btn btn-info btn-block mb-3 py-5 px-2 "
                        onClick={(e) => clearMsg()}
                      >
                        {messages.label.placeAnOrder}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
