import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import Navbar from "../layouts/Navbar";
import EssityContext from "../../context/EssityContext";
import SingleCategorie from "./SingleCategories";
import Spinner from "../utils/Spinner";
import PaginationServerSide from "../utils/PaginationServerSide";

const messages = {
  label: {
    goBack: "Home",
    theClient: "The Client",
    hasNoCategories: "Has No Categories",
    hasNoCategoriesFound: "No Categories Found",
    show: "Show",
    entries: "entries",
  },
};

const Categories = ({ match }) => {
  const history = useHistory();
  const {
    getAllCategories,
    categories,
    client,
    loading,
    clearMsg,
    setCurrentClientUpdateData,
    clearAllCategories,
    categoryPaginationAndSearchingState,
    categorySkuPaginationAndSearchingState,
    categoryPagination,categorySkuPagination
  } = useContext(EssityContext);
  const [searchCategoryValue, setSearchCategoryValue] = useState("");
  let [categoryState, setCategoryState] = useState({
    // limit: 2,
    // page: 1,
    limit: categoryPagination?.limit ? categoryPagination?.limit : 20,
    page: categoryPagination?.page ? categoryPagination?.page : 1,
    limitOptionArray: [20, 50, "All"],
    searchCategory: false,
  });
  const clientName = client && client?.name;

  const callbackFunctionFromPagination = (page) => {
    setCategoryState({
      ...categoryState,
      page: parseInt(page),
    });
  };
  useEffect(() => {
    setCurrentClientUpdateData(match.params.essity, history);
  }, []);

  useEffect(() => {
    getAllCategories(
      match.params.essity,
      searchCategoryValue,
      categoryState.limit,
      categoryState.page,
      client && client
    );
    setCategoryState({
      ...categoryState,
      searchCategory: false,
    });
    return;
  }, [clientName, categoryState.limit, categoryState.page]);

  const getCategoryBySearchValue = () => {
    // getAllCategories(
    //   match.params.essity,
    //   searchCategoryValue,
    //   categoryState.limit,
    //   1,
    //   client && client
    // );
    // setCategoryState({
    //   ...categoryState,
    //   searchCategory: false,
    // });
    if(!_.isEmpty(searchCategoryValue)){
      history.push({
        pathname: `/${match.params.essity}/categories/${searchCategoryValue}`,
        state: { categorySkusAndDiscriptionFilter:true },
      });
    }
  };

  const setChangeLimit = (newLimit) => {
    setCategoryState({
      ...categoryState,
      limit: newLimit,
      page: 1,
    });
  };

  useEffect(async () => {
    await categoryPaginationAndSearchingState(categoryState.page,categoryState.limit);
  },[
    categoryState.page,categoryState.limit,searchCategoryValue
  ])

  const clearSearchFiledAndValueOfCategorySku = async ()=>{
    if(!_.isEmpty(categorySkuPagination?.searchField) && !_.isEmpty(categorySkuPagination?.searchValue) ){
      await categorySkuPaginationAndSearchingState("",categorySkuPagination.limit,"","")
    }
  }

  return (
    <>
      <Navbar
        logout={true}
        cart={true}
        name={match.params.essity}
        logo={client?.photo || ""}
      ></Navbar>
      <div className="categories">
        <div
          className="container-fluid px-5 mb-4"
          style={{ marginTop: "20px" }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2 mr-2 mt-1">
                  <Link
                    className="btn button"
                    to={`/${match.params.essity}/dashboard`}
                    onClick={(e) => {
                      clearMsg();
                      clearAllCategories();
                    }}
                  >
                    {messages.label.goBack}
                  </Link>
                </div>
                <div className="col-md-6 mt-1">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control "
                      placeholder={`Search by SKU or Name`}
                      aria-describedby="basic-addon2"
                      onChange={(e) => {
                        setSearchCategoryValue(e.target.value);
                        setCategoryState({
                          ...categoryState,
                          searchCategory: true,
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text selectList"
                        id="basic-addon2"
                        style={{ cursor: "pointer" }}
                        onClick={getCategoryBySearchValue}
                      >
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="show-limit">
                    <div className="show-limit-inner">
                      <p className="pt-3" style={{ fontWeight: "bold" }}>
                        {messages.label.show + "  "}
                      </p>
                      <select
                        name="range"
                        className="form-control mx-1"
                        style={{ width: "70px" }}
                        onChange={(e) => setChangeLimit(e.target.value)}
                        value={categoryState?.limit}
                      >
                        {categoryState.limitOptionArray.map((limitValue) => (
                          <option key={limitValue} value={limitValue}>
                            {limitValue}
                          </option>
                        ))}
                      </select>
                      <p className="pt-3" style={{ fontWeight: "bold" }}>
                        {messages.label.entries}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {categories?.data?.length !== 0 ? (
            <>
              {loading ? (
                <Spinner></Spinner>
              ) : (
                <>
                  <div className="row">
                    {categories?.data?.map((category) => (
                      <div className="col-md-4" key={category._id}>
                        <SingleCategorie
                          category={category}
                          match={match.params.essity}
                          searchCategoryValue={searchCategoryValue}
                          clearSearchFiledAndValueOfCategorySku={clearSearchFiledAndValueOfCategorySku}
                        ></SingleCategorie>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <PaginationServerSide
                callBack={callbackFunctionFromPagination}
                total={categories.totalPages}
                initialPage={categoryState?.page - 1}
              />
            </>
          ) : (
            <p className="lead text-center mt-5" style={{ fontWeight: "bold" }}>
              {messages.label.theClient} {clientName}{" "}
              {messages.label.hasNoCategories}{" "}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Categories;
